@import "../dist/angular-frontend-components/src/lib/styles/index";
@import "styles/index";

body {
  overflow-x: hidden;
}

body,
div,
button,
input,
select,
textarea {
  line-height: initial;
}

.no-decoration {
  text-decoration: none;
}

.no-decoration:hover {
  text-decoration: none;
}

.orient-blue-background {
  background: $vdab-blue-primary;
}

.orient-green-background {
  background: $vdab-green;
}

.orient-orange-background {
  background: $vdab-orange;
}

.orient-light-blue-background {
  background: $vdab-light-blue;
}

.card-container {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  height: 100%;
}

.orient-app-container-wrapper {
  margin: 0 auto;
  max-width: 1300px;
  display: inherit;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.grecaptcha-badge {
  display: none;
}
