@import "variables";

.u-display-inline-block {
  display: inline-block !important;
}

.u-hidden {
  display: none;
}

.u-p05 {
  padding: 0.5rem !important;
}

.u-p1 {
  padding: 1rem !important;
}

.u-p15 {
  padding: 1.5rem;
}

.u-pr15 {
  padding-right: 1.5rem;
}

.u-pt0 {
  padding-top: 0 !important;
}

.u-pt05 {
  padding-top: 0.5rem !important;
}

.u-pt1 {
  padding-top: 1rem;
}

.u-pt15 {
  padding-top: 1.5rem;
}

.u-pl15 {
  padding-left: 1.5rem;
}

.u-pb15 {
  padding-bottom: 1.5rem;
}

.u-pt025 {
  padding-top: 0.25rem;
}

.u-py05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.u-px05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.u-px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.u-my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.u-my025 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.u-my05 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.u-my1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.u-my15 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.u-mb1 {
  margin-bottom: 1rem !important;
}

.u-mb15 {
  margin-bottom: 1.5rem !important;
}

.u-mr025 {
  margin-right: 0.25rem !important;
}

.u-mr05 {
  margin-right: 0.5rem !important;
}

.u-mr1 {
  margin-right: 1rem !important;
}

.u-mr15 {
  margin-right: 1.5rem !important;
}

.u-ml025 {
  margin-left: 0.25rem !important;
}

.u-ml05 {
  margin-left: 0.5rem !important;
}

.u-ml1 {
  margin-left: 1rem !important;
}

.u-ml15 {
  margin-left: 1.5rem !important;
}

.u-mt0 {
  margin-top: 0 !important;
}

.u-mt1 {
  margin-top: 1rem !important;
}

.u-mt2 {
  margin-top: 2rem !important;
}

.u-mt3 {
  margin-top: 3rem !important;
}

.u-mx025 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.u-mx05 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.u-mx1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.u-mx15 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

// Source: mail.google.com/mail/u/0/?zx=swxl46la26ft#chat/dm/uPV994AAAAE
.full-width-override {
  width: 100vw !important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media only screen and (min-width: $medium-screen-bp) {
  .hide-l-and-up {
    display: none !important;
  }
}

@media only screen and (max-width: $medium-screen-bp) {
  .hide-md-and-down {
    display: none !important;
  }

  .u-m025-md {
    margin: 0.25rem;
  }

  .u-m05-md {
    margin: 0.5rem;
  }

  .u-m1-md {
    margin: 1rem;
  }
}

@media only screen and (min-width: $small-screen-vdab-bp) {
  .hide-md-and-up-vdab-bp {
    display: none !important;
  }
}

@media only screen and (max-width: $small-screen-bp) {
  .u-m025-sm {
    margin: 0.25rem;
  }

  .u-m05-sm {
    margin: 0.5rem;
  }

  .u-m1-sm {
    margin: 1rem;
  }

  .hide-sm-and-down {
    display: none !important;
  }

  .o-wrapper {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
