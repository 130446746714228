@import "_variables.scss";

.fab {
  display: grid !important;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  border: none;
  text-align: center !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

}

.elevated {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 20%), 0px 6px 10px 0px rgba(0, 0, 0, 14%), 0px 1px 18px 0px rgba(0, 0, 0, 12%);
}

.small-btn {
  width: 2rem !important;
  height: 2rem !important;

  span {
    font-size: 1.5rem;
  }
}

.large-btn {
  width: 3.5rem !important;
  height: 3.5rem !important;

  span {
    font-size: 1.5rem;
  }
}
