@import "variables";

.u-display-hidden {
  display: none !important;
}

.spacer {
  display: flex;
  flex: 1;
}

.u-p1 {
  padding: 1rem !important;
}

.u-p15 {
  padding: 1.5rem !important;;
}

.u-pr15 {
  padding-right: 1.5rem !important;;
}

.u-pt15 {
  padding-top: 1.5rem !important;;
}

.u-pl15 {
  padding-left: 1.5rem !important;;
}

.u-pb15 {
  padding-bottom: 1.5rem !important;;
}

.u-pt0 {
  padding-top: 0 !important;
}

.u-pt025 {
  padding-top: 0.25rem !important;
}

.u-pt05 {
  padding-top: 0.5rem !important;
}

.u-pt1 {
  padding-top: 1rem !important;
}

.u-px0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-px025 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.u-px05 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.u-px1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.u-px15 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.u-my0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.u-my025 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.u-my05 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.u-my1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.u-my15 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}


.u-mb0 {
  margin-bottom: 0rem !important;
}

.u-mb1 {
  margin-bottom: 1rem !important;
}

.u-mb15 {
  margin-bottom: 1.5rem !important;
}

.u-mr025 {
  margin-right: 0.25rem !important;
}


.u-mr05 {
  margin-right: 0.5rem !important;
}

.u-mr1 {
  margin-right: 1rem !important;
}

.u-mr15 {
  margin-right: 1.5rem !important;
}

.u-mr4 {
  margin-right: 4rem !important;
}

.u-ml025 {
  margin-left: 0.25rem !important;
}


.u-ml05 {
  margin-left: 0.5rem !important;
}

.u-ml1 {
  margin-left: 1rem !important;
}

.u-ml15 {
  margin-left: 1.5rem !important;
}


.u-mt0 {
  margin-top: 0rem !important;
}

.u-mt025 {
  margin-top: 0.25rem !important;
}


.u-mt05 {
  margin-top: 0.5rem !important;
}

.u-mt1 {
  margin-top: 1rem !important;
}

.u-mt2 {
  margin-top: 2rem !important;
}

.u-mt3 {
  margin-top: 3rem !important;
}

.u-mx025 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.u-mx05 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.u-mx1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.u-mx15 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

// Source: mail.google.com/mail/u/0/?zx=swxl46la26ft#chat/dm/uPV994AAAAE
.full-width-override {
  width: 100vw !important;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media only screen and (min-width: $medium-screen-bp) {
  .hide-l-and-up {
    display: none !important;
  }
}

@media only screen and (max-width: $medium-screen-bp) {


  .hide-md-and-down {
    display: none !important;
  }

  .u-m025-md {
    margin: 0.25rem !important;
  }
  .u-m05-md {
    margin: 0.5rem !important;
  }
  .u-m1-md {
    margin: 1rem !important;
  }
}

@media only screen and (max-width: $small-screen-bp) {
  .u-m025-sm {
    margin: 0.25rem !important;
  }
  .u-m05-sm {
    margin: 0.5rem !important;
  }
  .u-m1-sm {
    margin: 1rem !important;
  }

  .hide-sm-and-down {
    display: none !important;
  }

  .o-wrapper {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
}
