$small-screen-bp: 600px;
$small-screen-vdab-bp: 681px;
$medium-screen-bp: 960px;
$large-screen-bp: 1370px;
$vdab-blue-100: #eff7fe;
$vdab-blue-200: #dbecf8;
$vdab-blue-300: #c4def0;
$vdab-blue-400: #82bfff;
$vdab-blue-500: #2d89cc;
$vdab-blue-700: #0a5d98;
$vdab-blue-900: #004475;
$vdab-blue: $vdab-blue-500;
$vdab-indigo-900: #041847;
$vdab-grey-100: #f8f7f8;
$vdab-grey-300: #e3e5e7;
$vdab-grey-700: #0a5d98;
$vdab-grey-800: #6a707b;
$vdab-grey-900: #474b50;
$vdab-grey-light: #d0d0d9;
$vdab-grey: #a2a2a8;
$vdab-grey-primary-meta: #6a707b;
$vdab-red-700: #c80001;
$vdab-blue-primary: $vdab-blue-500;
$vdab-blue-primary-heading: #102759;
$vdab-green: #67c721;
$vdab-orange: #ff9d00;
$vdab-light-blue: #eff7fe;
$vdab-white: #fff;
$vdab-turqoise-500: #49b4aa;
$vdab-turqoise-700: #33812a;
$vdab-turqoise-900: #004747;
$rwmmp: #3264fe;
$rwmmp: #dadae5;
$font-size-base: 16px;
$font-size-h3: ceil(($font-size-base * 1.45)); // ~24px
