@import "_variables";

.fab {
  display: grid !important;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  border: none;
  text-align: center !important;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.elevated {
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
}

.small-btn {
  width: 2rem !important;
  height: 2rem !important;

  span {
    font-size: 1.5rem;
  }
}

.large-btn {
  width: 3.5rem !important;
  height: 3.5rem !important;

  span {
    font-size: 1.5rem;
  }
}
