@import "_variables";

.page-title {
  z-index: 1;
  font-size: 1.7rem;
  font-weight: bold;
}

.vdab-text-center {
  text-align: center;
}

.vdab-text-color-white {
  color: $vdab-white !important;
  text-decoration-color: $vdab-white !important;
}

.vdab-text-color-grey {
  color: $vdab-grey !important;
  text-decoration-color: $vdab-grey !important;
}

.vdab-text-color-blue {
  color: $vdab-blue-primary !important;
  text-decoration-color: $vdab-blue-primary !important;
}

.vdab-text-color-green {
  color: $vdab-green !important;
  text-decoration-color: $vdab-green !important;
}

.u-text-align-center {
  text-align: center !important;
}

.u-text-align-end {
  text-align: end !important;
}
